import * as React from 'react'
import { Link } from 'gatsby'
import routes from '../common/routes'
import { toSlug } from '../common/toSlug'
import { useLocations } from '../hooks/useLocations'
import getLocationRoute from '../lib/routing/getLocationRoute'

export default function Locations() {
  const locations = useLocations()

  return (
    <div className="container pb-8">
      <h1>ALL LOCATIONS SERVED</h1>
      <p>Foundation Experts serve the following locations throughout Wisconsin.</p>
      <ol className="ml-4 list-decimal">
        {locations.map(({ node }) => (
          <li key={node.frontmatter.name}>
            <Link
              className="underline hover:font-bold"
              to={getLocationRoute(node.frontmatter.name)}
            >
              {node.frontmatter.name} Foundation Repair and Water Proofing
            </Link>
          </li>
        ))}
      </ol>
    </div>
  )
}
